import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { HelpCenterSettings } from '@mp/shared/helper-links/help-center/domain';

export const HelpCenterActions = createActionGroup({
  source: 'Help Center',
  events: {
    fetchHelpCenterSettings: emptyProps(),
    fetchHelpCenterSettingsSuccess: props<{ helpCenterSettings: HelpCenterSettings }>(),
    fetchHelpCenterSettingsError: emptyProps(),

    updateHelpCenterSettings: props<{ helpCenterSettings: HelpCenterSettings }>(),
    updateHelpCenterSettingsSuccess: props<{ helpCenterSettings: HelpCenterSettings }>(),
    updateHelpCenterSettingsError: emptyProps(),
  },
});
