import { createReducer, on } from '@ngrx/store';

import { HelpCenterSettings } from '@mp/shared/helper-links/help-center/domain';

import { HelpCenterActions } from './help-center.actions';

export const helpCenterFeatureKey = 'mp-help-center';

export interface HelpCenterState {
  helpCenterSettings: HelpCenterSettings | undefined;
  isLoading: boolean;
}

export const initialState: HelpCenterState = {
  helpCenterSettings: undefined,
  isLoading: false,
};

export const helpCenterReducer = createReducer(
  initialState,
  on(
    HelpCenterActions.fetchHelpCenterSettings,
    (state: HelpCenterState): HelpCenterState => ({
      ...state,
      helpCenterSettings: undefined,
      isLoading: true,
    }),
  ),
  on(
    HelpCenterActions.fetchHelpCenterSettingsSuccess,
    (state: HelpCenterState, { helpCenterSettings }): HelpCenterState => ({
      ...state,
      helpCenterSettings,
      isLoading: false,
    }),
  ),
  on(
    HelpCenterActions.fetchHelpCenterSettingsError,
    (state: HelpCenterState): HelpCenterState => ({
      ...state,
      helpCenterSettings: undefined,
      isLoading: false,
    }),
  ),

  on(
    HelpCenterActions.updateHelpCenterSettings,
    (state: HelpCenterState): HelpCenterState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    HelpCenterActions.updateHelpCenterSettingsSuccess,
    (state: HelpCenterState, { helpCenterSettings }): HelpCenterState => ({
      ...state,
      helpCenterSettings,
      isLoading: false,
    }),
  ),
  on(
    HelpCenterActions.updateHelpCenterSettingsError,
    (state: HelpCenterState): HelpCenterState => ({
      ...state,
      isLoading: false,
    }),
  ),
);
