import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HelpCenterSettings } from '@mp/shared/helper-links/help-center/domain';

import { HelpCenterActions } from './help-center.actions';
import { selectHelpCenterSettings, selectIsLoading } from './help-center.selectors';

@Injectable()
export class HelpCenterFacade {
  readonly helpCenterSettings$: Observable<HelpCenterSettings | undefined> =
    this.store$.select(selectHelpCenterSettings);

  readonly isLoading$: Observable<boolean> = this.store$.select(selectIsLoading);

  readonly updateHelpCenterSettingsSuccess$: Observable<unknown> = this.actions$.pipe(
    ofType(HelpCenterActions.updateHelpCenterSettingsSuccess),
  );

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
  ) {}

  fetchHelpCenterSettings(): void {
    this.store$.dispatch(HelpCenterActions.fetchHelpCenterSettings());
  }

  updateHelpCenterSettings(helpCenterSettings: HelpCenterSettings): void {
    this.store$.dispatch(HelpCenterActions.updateHelpCenterSettings({ helpCenterSettings }));
  }
}
